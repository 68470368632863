.dogs-container {
  max-width: 85%;
  margin: auto;
}
.my-dog-heading {
  font-size: 28px !important;
  text-align: center;
  font-family: Brandon !important;
  margin-left: auto !important;
  margin-right: auto !important;
}
.dogs-wrapper {
  display: flex;
  gap: 40px;
  justify-content: center;
  flex-wrap: wrap;
}
.dog-widget {
  position: relative;
  width: max-content;
  cursor: pointer;
}
.dog-widget-avatar {
  width: 150px !important;
  height: 150px !important;
  border: 1px solid #80808075
}
.dog-widget-edit {
  background: #f48220;
  color: white;
  border-radius: 6px;
  padding: 2px;
  position: absolute;
  right: 0;
  top: 10px;
  cursor: pointer;
}
.new-dog-avatar {
  width: 150px !important;
  height: 150px !important;
  cursor: pointer !important;
  border: 1px solid #80808075
}
.new-dog-icon {
  width: 50px;
}
.dog-info-container {
  box-shadow: 2px 4px 30px #bdbdbd;
  border-radius: 8px;
  padding: 15px;
  margin-top: 70px !important;
  margin: 0 auto;
  width: 40%;
}
.dog-info-typo {
  font-family: Brandon !important;
  font-size: 22px !important;
  font-weight: 900;
}
.dog-info-typo span {
    color: #f48220;
  }

  /* View Dog CSS Starts Here */
.view-dog-container{
    max-width: 85%;
    margin: auto;
    margin-bottom: 20px !important;
}
  .view-dog-heading{
    font-size: 28px !important;
    text-align: center;
    font-family: Brandon !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .view-dog-images{
    width: 250px;
    aspect-ratio: 1/1;
    border: 1px solid #80808075;
    margin-right: 5px;
    object-fit: contain;
    border-radius: 5px;
    padding: 10px;
  }

  .view-sub-heading{
    width: 50%;
    font-size: 18px !important;
    text-align: center;
    font-family: Brandon !important;
  }

  /* .Mui-disabled{
    background: #f4822026;
    -webkit-text-fill-color: black
  } */

  .higlight-avatar{
    border: 2px solid #f48220;
  }

  .disabled-field{
    background-color: #f4822021 !important;
    border-radius: 4px
  }

  .dog-edit-action{
    background: #f48220 !important;
    color: white !important;
    cursor: pointer;
  }

  .dog-delete-action{
    margin-left: 5px !important;
    background: #d32f2f !important;
    color: white !important;
    cursor: pointer;
  }


  @media (max-width: 600px){
    .dog-info-container{
      display: none;
    }
    .view-sub-heading{
      width: 100%;
    }
  }