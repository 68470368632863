.location-options{
    display: flex;
}
.location-option{
    display: grid;
}
@media (max-width: 900px){
    .location-options{
        flex-direction: column !important;
        flex-wrap: wrap !important;
    }  
    .checkbox-container{
        width: 15rem;
    }
}